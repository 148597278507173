import { cilArrowLeft } from "@coreui/icons";
import CIcon from "@coreui/icons-react";
import {
  CButton,
  CButtonGroup,
  CCard,
  CCardBody,
  CCardText,
  CCardTitle,
  CCol,
  CContainer,
  CFormCheck,
  CImage,
  CListGroup,
  CListGroupItem,
  CRow,
  CSpinner,
  CToast,
  CToastBody,
  CToastClose,
  CToaster,
} from "@coreui/react";
import React, { useRef, useState, useEffect } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import useApiDataStore from "../../useApiDataStore";
import "./FoodItemDetails.css";
import useTheme from "../../hooks/useTheme";

const DEFAULT_SIZE = "Normal";

const FoodItemDetails = () => {
  const { _, id: foodItemId } = useParams();
  const navigate = useNavigate();
  const { info, order, setOrder, getItemById } = useApiDataStore();
  const theme = useTheme();
  const {
    id,
    name,
    category,
    description,
    price,
    photo,
    requiredChoices,
    otherSizes,
    extras,
    popular,
    outOfStock,
    // tags,
  } = getItemById(foodItemId);
  const [quantity, setQuantity] = useState(1);
  const [selectedExtras, setSelectedExtras] = useState({});
  const [selectedRequiredChoices, setSelectedRequiredChoices] = useState({});
  const [selectedOtherSize, setSelectedOtherSize] = useState(null);
  const [additionalNote, setAdditionalNote] = useState("");
  const [toast, addToast] = useState(0);
  const toaster = useRef();
  const [validationError, setValidationError] = useState(false);
  const location = useLocation();
  const [shouldWiggle, setShouldWiggle] = useState(location.state?.fromQuickAdd);

  const wiggleStyle = {
    animation: shouldWiggle ? 'wiggle 0.5s ease-in-out' : 'none'
  };

  useEffect(() => {
    if (shouldWiggle) {
      const timer = setTimeout(() => {
        setShouldWiggle(false);
      }, 1500);
      return () => clearTimeout(timer);
    }
  }, [shouldWiggle]);

  const handleSelectionChange = (group, key, value) => {
    switch (group) {
      case "extras":
        setSelectedExtras((prev) => ({ ...prev, [key]: value }));
        break;
      case "otherSize":
        setSelectedOtherSize((prev) => {
          if (prev && prev[key] === value) {
            // If the same checkbox is unchecked, clear selection
            return null;
          }
          // Otherwise, set the new selection
          return { [key]: value };
        });
        break;
      default:
        break;
    }
  };

  const handleRequiredChoiceChange = (group, choice, value) => {
    setSelectedRequiredChoices((prev) => ({
      ...prev,
      [group]: choice,
    }));
  };

  const calculateTotalPrice = () => {
    const foodItem = getItemById(foodItemId);
    const extrasTotal = Object.values(selectedExtras).reduce(
      (acc, curr) => acc + curr,
      0
    );
    const requiredChoicesTotal = Object.keys(selectedRequiredChoices)
      .map((rc) => foodItem.requiredChoices[rc][selectedRequiredChoices[rc]])
      .reduce((acc, curr) => acc + curr, 0);
    const otherSizePrice = selectedOtherSize
      ? Object.values(selectedOtherSize)[0]
      : 0;
    const finalBasePrice = selectedOtherSize ? otherSizePrice : price;

    return (finalBasePrice + extrasTotal + requiredChoicesTotal) * quantity;
  };

  function addToCurrentOrder() {
    if (!validateRequiredChoices()) {
      setValidationError(true);
      addToast(createValidationErrorToast());
      return;
    }

    const itemKey =
      `${id}-` +
      `${otherSizes ? "-" + Object.keys(otherSizes).join("-") : ""}` +
      `${requiredChoices
        ? "-" + Object.values(selectedRequiredChoices).join("-")
        : ""
      }` +
      `${extras ? "-" + Object.keys(selectedExtras).join("-") : ""}` +
      `${additionalNote ? "-" + additionalNote : ""}`;

    const foodItemIndex = order.foodItems.findIndex(
      (item) => item.key === itemKey
    );

    if (foodItemIndex === -1) {
      const newItem = {
        id: id,
        key: itemKey,
        name: name,
        quantity: quantity || 1,
        otherSizes: selectedOtherSize || {},
        requiredChoices: selectedRequiredChoices || {},
        extras: selectedExtras || {},
        additionalNote: null,
        //totalPrice: calculateTotalPrice(),
      };
      order.foodItems.push(newItem);
    } else {
      order.foodItems[foodItemIndex].quantity += 1;
    }

    setOrder({ ...order });
    resetSelections();
    setValidationError(false);
    addToast(createToast());
  }

  const resetSelections = () => {
    setQuantity(1);
    setSelectedExtras({});
    setSelectedRequiredChoices({});
    setSelectedOtherSize(null);
  };

  const validateRequiredChoices = () => {
    if (requiredChoices && Object.keys(requiredChoices).length > 0) {
      for (let group of Object.keys(requiredChoices)) {
        if (!selectedRequiredChoices[group]) {
          return false; // If any required choice is missing, return false
        }
      }
    }
    return true; // All required choices are selected
  };

  const createValidationErrorToast = () => (
    <CToast
      autohide={true}
      visible={true}
      className="p-3 mb-5 w-100 align-items-center"
      style={{
        backgroundColor: theme.palette.error,
        color: theme.palette.onError,
      }}
    >
      <div className="d-flex">
        <CToastBody style={{ fontSize: "large" }}>
          Veuillez sélectionner toutes les options requises avant d'ajouter
          l'article à votre panier.
        </CToastBody>
        <CToastClose className="me-2 m-auto" color={theme?.palette?.primary.main || theme?.palette?.primary} />
      </div>
    </CToast>
  );

  const createToast = () => (
    <CToast
      animation
      autohide={true}
      visible={true}
      color={theme.palette.textPrimary}
      className="p-3 mb-5 w-100 align-items-center"
      style={{
        backgroundColor: theme.palette.success.main,
        color: theme.palette.textPrimary,
      }}
    >
      <div className="d-flex">
        <CToastBody style={{ color: theme.palette.success.contrastText, fontSize: "large" }}>
          {quantity} {name}
          {quantity > 1 ? "s" : ""} {quantity > 1 ? "ont" : "a"} bien été
          noté(e)
          {quantity > 1 ? "s" : ""} dans votre récapitulatif!
        </CToastBody>
        <CToastClose
          className="me-2 m-auto"
          variant="outline"
          size="lg"
          color={theme.palette.textPrimary}
          style={{ color: theme.palette.textPrimary, "--cui-btn-close-color": theme.palette.textPrimary }}
        />
      </div>
    </CToast>
  );

  const renderChoiceGroup = (group, choices, stateKey) => (
    <SelectionCard title={group}>
      <CListGroup flush>
        {Object.keys(choices).map((choice, index) => (
          <CListGroupItem
            key={"group-" + index}
            style={{
              background: "transparent",
              color: theme.palette.textPrimary,
              border: 0,
            }}
          >
            <CFormCheck
              disabled={outOfStock === true || order.status === "VALIDATED"}
              type="radio"
              name={group}
              id={`${group}-${choice}`}
              label={`${choice} (+${choices[choice]} ${info.currency})`}
              checked={stateKey === choice}
              onChange={() =>
                handleRequiredChoiceChange(group, choice, choices[choice])
              }
            />
          </CListGroupItem>
        ))}
      </CListGroup>
    </SelectionCard>
  );

  const commonCardStyle = {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "flex-start",
    padding: "0px",
    gap: "10px",
    width: "100%",
    border: "1px solid #ccc",
    marginBottom: "24px",

    fontFamily: theme.typography.fonts[0].name,
    fontSize: "16px",
  };

  const SelectionCard = ({ title, children }) => (
    <CCard
      style={{
        background: "transparent",
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "flex-start",
        padding: "0px",
        width: "100%",
      }}
    >
      <CCardBody className="p-1" style={{ background: "transparent", flex: 1 }}>
        {title && (
          <CCardText
            style={{
              color: theme.palette.textPrimary,
              fontFamily: '"Inter", system-ui',
              fontSize: "16px",
              fontWeight: 600,
              borderBottom: "1px solid " + theme.palette.textPrimary,
            }}
          >
            {title}
          </CCardText>
        )}
        {children}
      </CCardBody>
    </CCard>
  );

  if (!theme || !order?.id) {
    return <div className="w-100 h-100 d-flex justify-content-center align-items-center">
      <CSpinner variant="grow" />
    </div>;
  }

  document.title = `${name}`;

  return (
    <CContainer
      fluid
      className="g-0 p-1 h-100 d-flex flex-column"
      style={{
        color: theme.palette.textPrimary,
        overflowX: "hidden",
        boxShadow: "0px -4px 8px rgba(0, 0, 0, 0.8)"
      }}
    >
      <CButton
        size="lg"
        className={`rounded-circle border back-button d-block`}
        color="white"
        onClick={() => navigate(-1, { state: { category: category } })}
      >
        <CIcon
          size="xxl"
          icon={cilArrowLeft}
          style={{ height: "100%", width: "100%" }}
        />
      </CButton>

      {photo ? (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexShrink: 0,
            overflow: "hidden",
            width: "100%",
            aspectRatio: "1/1",
          }}
        >
          <picture style={{ width: "100%", height: "100%", display: "block" }}>
            {photo.original.webp && (
              <source srcSet={photo.original.webp} type="image/webp" />
            )}
            {photo.original.avif && (
              <source srcSet={photo.original.avif} type="image/avif" />
            )}
            <CImage
              srcSet={photo.original.jpg} // Ensure the correct source is being used
              className="zoom-in-out2"
              align="center"
              style={{
                width: "100%",
                height: "100%",
                aspectRatio: "1/1",
                objectFit: "cover", // Ensure the image covers the entire container
                display: "block",
              }}
            />
          </picture>
        </div>
      ) : (
        <></>
      )}

      <CCard
        className="w-100 flex-grow-1 p-2"
        style={{
          backgroundColor: theme.palette.background,
          // color: 'black',
          borderRadius: 0,
          //borderColor: '#D9D9D9',
          boxShadow: "0px -4px 8px rgba(0, 0, 0, 0.9)",
        }}
      >
        <CCardBody
          className="d-flex flex-column"
          style={{
            height: "100%",
            marginTop: photo ? 0 : "15vh",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          <CCardTitle className="mb-0">
            <CRow>
              <CCol
                xs={12}
                className="pb-3"
                style={{
                  /* M3/title/large */
                  fontFamily: "Merriweather",
                  fontStyle: "normal",
                  // fontWeight: 400,
                  lineHeight: "28px",
                  fontSize: "clamp(8px, 8vw, 48px)",
                  fontWeight: "bold",
                  color: theme.palette.textPrimary,
                }}
              >
                {name}
              </CCol>
              {/* <CCol xs={12}>
                {name} {tags && tags.map((tag) => tag.label)}
                {popular && <CButton className="text-white p-1 rounded" style={{ fontSize: 12, fontWeight: "bold", whiteSpace: "nowrap" }}>Populaire!</CButton>}
                {outOfStock && <CButton className="bg-danger text-white mx-2 p-1 rounded" style={{ fontSize: 10, fontWeight: "bold", whiteSpace: "nowrap" }}>Indisponible</CButton>}
              </CCol> */}
            </CRow>
            {/* if popular is true, display a box that says "Cette article est populaire chez nos clients" */}
            {popular && (
              <CCard className="my-3 py-2" style={{ background: theme.palette.secondary, color: theme.palette.onSecondary, borderRadius: 0, marginBottom: "24px" }}>
                <CCardBody style={{ flex: 1, padding: "10px" }}>
                  <CCardText style={{ fontSize: "16px", fontWeight: 400 }}>
                    ⭐ Cet article est populaire chez nos clients.
                  </CCardText>
                </CCardBody>
              </CCard>
            )}
            <CRow>
              <p
                style={{
                  color: theme.palette.textPrimary,
                  fontFamily: '"Inter", system-ui',
                  fontWeight: 400,
                }}
              >
                {price} {info.currency}
              </p>
            </CRow>
          </CCardTitle>
          <CContainer className="d-flex flex-column flex-grow-1">
            {(description ||
              outOfStock === true ||
              (otherSizes && Object.keys(otherSizes).length > 0) ||
              (requiredChoices && Object.keys(requiredChoices).length > 0) ||
              (extras && Object.keys(extras).length > 0)) && (
                <div className="p-0 flex-grow-1">
                  {description && (
                    <CCard
                      id="you"
                      style={{
                        ...commonCardStyle,
                        background: "transparent",
                        color: theme.palette.textPrimary,
                      }}
                    >
                      <CCardBody style={{ flex: 1, padding: "10px" }}>
                        <CCardText>{description}</CCardText>
                      </CCardBody>
                    </CCard>
                  )}

                  {outOfStock === true && (
                    <CCard
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                        alignItems: "flex-start",
                        padding: "0px",
                        gap: "10px",
                        width: "100%",
                        border: "1px solid #ccc",
                        borderRadius: "0px",
                        marginBottom: "24px",
                        fontFamily: '"Inter", system-ui',
                        fontSize: "16px",
                      }}
                      color="error"
                    >
                      <CCardBody style={{ flex: 1, padding: "10px" }}>
                        <CCardText>
                          Cet article est indisponible pour le moment.
                        </CCardText>
                      </CCardBody>
                    </CCard>
                  )}

                  {requiredChoices && Object.keys(requiredChoices).length > 0 && (
                    <div className="pt-2 pb-5" style={wiggleStyle}>
                      <CCardText
                        style={{
                          color: theme.palette.textPrimary,
                          fontFamily: '"Inter", system-ui',
                          fontSize: "20px",
                          fontWeight: 700,
                        }}
                      >
                        Choix requis
                      </CCardText>
                      {Object.keys(requiredChoices).map((group, i) =>
                        renderChoiceGroup(
                          group,
                          requiredChoices[group],
                          selectedRequiredChoices[group]
                        )
                      )}
                    </div>
                  )}

                  {otherSizes && Object.keys(otherSizes).length > 0 && (
                    <div style={wiggleStyle}>
                      <CCardText
                        style={{
                          color: theme.palette.textPrimary,
                          fontFamily: '"Inter", system-ui',
                          fontSize: "20px",
                          fontWeight: 700,
                        }}
                      >
                        Tailles
                      </CCardText>
                      <SelectionCard>
                        <CListGroup flush>
                          <CListGroupItem
                            style={{
                              background: "transparent",
                              color: theme.palette.textPrimary,
                              border: 0,
                            }}
                          >
                            <CFormCheck
                              disabled={outOfStock === true || order.status === "VALIDATED"}
                              type="checkbox"
                              name="otherSize"
                              id={`size-${DEFAULT_SIZE}`}
                              label={`${DEFAULT_SIZE} - ${price} ${info.currency}`}
                              checked={!selectedOtherSize}
                              onChange={() => setSelectedOtherSize(null)}
                            />
                          </CListGroupItem>
                          {otherSizes &&
                            Object.keys(otherSizes).map((size, index) => (
                              <CListGroupItem
                                key={`size-${index}`}
                                style={{
                                  background: "transparent",
                                  color: theme.palette.textPrimary,
                                  border: 0,
                                }}
                              >
                                <CFormCheck
                                  disabled={outOfStock === true || order.status === "VALIDATED"}
                                  type="checkbox"
                                  name="otherSize"
                                  id={`size-${size}`}
                                  label={`${size} - ${otherSizes[size]} ${info.currency}`}
                                  checked={selectedOtherSize && selectedOtherSize[size] === otherSizes[size]}
                                  onChange={() => handleSelectionChange("otherSize", size, otherSizes[size])}
                                />
                              </CListGroupItem>
                            ))}
                        </CListGroup>
                      </SelectionCard>
                    </div>
                  )}

                  {extras && Object.keys(extras).length > 0 && (
                    <>
                      <CCardText
                        style={{
                          color: theme.palette.textPrimary,
                          fontFamily: '"Inter", system-ui',
                          fontSize: "20px",
                          fontWeight: 700,
                        }}
                      >
                        Extras
                      </CCardText>
                      <SelectionCard>
                        <CListGroup flush>
                          {Object.keys(extras).map((extra, index) => (
                            <CListGroupItem
                              key={`extra-${index}`}
                              style={{
                                background: "transparent",
                                color: theme.palette.textPrimary,
                              }}
                            >
                              <CFormCheck
                                disabled={
                                  outOfStock === true ||
                                  order.status === "VALIDATED"
                                }
                                type="checkbox"
                                id={`extra-${extra}`}
                                label={`+ ${extra} (+${extras[extra]} ${info.currency})`}
                                checked={!!selectedExtras[extra]}
                                onChange={(e) =>
                                  handleSelectionChange(
                                    "extras",
                                    extra,
                                    e.target.checked ? extras[extra] : 0
                                  )
                                }
                              />
                            </CListGroupItem>
                          ))}
                        </CListGroup>
                      </SelectionCard>
                    </>
                  )}
                </div>
              )}
          </CContainer>
          <CRow className="my-3">
            {/* Quantity selector */}
            <CCol xs={5}>
              <CButtonGroup
                as="fieldset"
                className="c-button-group mb-2"
                style={{
                  background: theme?.palette?.primary.main || theme?.palette?.primary,
                  borderColor: theme.palette.textPrimary,
                }}
              >
                <CButton
                  disabled={outOfStock === true || order.status === "VALIDATED"}
                  variant="outline"
                  className="c-button"
                  style={{
                    borderRight: "none",
                    borderColor: theme.palette.textPrimary,
                    color: theme.palette.onPrimary,
                    fontSize: "small",
                    fontWeight: "bold",
                  }}
                  onClick={() => setQuantity(Math.max(quantity - 1, 1))}
                >
                  -
                </CButton>
                <CButton
                  variant="outline"
                  disabled
                  className="c-button"
                  style={{
                    opacity: "initial",
                    borderLeft: "none",
                    borderRight: "none",
                    borderColor: theme.palette.textPrimary,
                    color: theme.palette.onPrimary,
                    fontSize: "small",
                    fontWeight: "bold",
                  }}
                >
                  {quantity}
                </CButton>
                <CButton
                  disabled={outOfStock === true || order.status === "VALIDATED"}
                  variant="outline"
                  className="c-button"
                  style={{
                    borderLeft: "none",
                    borderColor: theme.palette.textPrimary,
                    color: theme.palette.onPrimary,
                    fontSize: "small",
                    fontWeight: "bold",
                  }}
                  onClick={() => setQuantity(Math.min(quantity + 1, 10))}
                >
                  +
                </CButton>
              </CButtonGroup>
            </CCol>
            {/* AJOUTER button */}
            <CCol xs={7}>
              <CButton
                disabled={
                  outOfStock === true ||
                  order.status === "VALIDATED" ||
                  !validateRequiredChoices()
                }
                onClick={addToCurrentOrder}
                style={{
                  background: theme?.palette?.primary.main || theme?.palette?.primary,
                  color: theme.palette.onPrimary,
                  borderColor: theme.palette.onPrimary,
                  width: "100%",
                  height: "100%",
                  fontWeight: "bold",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
                }}
              >
                <div style={{ fontSize: "small" }}>AJOUTER</div>
                <div style={{ fontSize: "small" }}>
                  {calculateTotalPrice()} {info.currency}
                </div>
              </CButton>
              <CToaster ref={toaster} push={toast} placement="bottom" />
            </CCol>
          </CRow>
        </CCardBody>
      </CCard>
    </CContainer>
  );
};

export default React.memo(FoodItemDetails);
