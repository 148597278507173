import { cibFacebook, cibInstagram, cibTiktok } from "@coreui/icons";
import CIcon from "@coreui/icons-react";
import {
  CButton,
  CContainer,
  CForm,
  CFormLabel,
  CFormTextarea,
  CImage,
  CRow,
  CToast,
  CToastBody,
  CToastClose,
  CToaster,
} from "@coreui/react";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import StarRating from "./StarRating";
import useApiDataStore from "../../useApiDataStore";
import { useRef } from "react";
import { trackButtonClick } from "../../analytics";
import useTheme from "../../hooks/useTheme";

const Rate = () => {
  const { info, links, submitRating } = useApiDataStore();
  const theme = useTheme();
  const [rating, setRating] = useState(0);
  const [message, setMessage] = useState("");
  const [toast, addToast] = useState(0);

  const toaster = useRef();
  const toastOk = (
    <CToast
      autohide={true}
      visible={true}
      className="p-3 mb-5 w-100 align-items-center"
      style={{ backgroundColor: theme.successColor, color: theme.activeTextColor }}
    >
      <div className="d-flex">
        <CToastBody style={{ fontSize: "large" }}>
          Merci pour votre commentaire. 🙏
        </CToastBody>
        <CToastClose className="me-2 m-auto" color={theme.activeTextColor} />
      </div>
    </CToast>
  );

  const handleRatingChange = (newRating) => {
    setRating(newRating);
    // Scroll to bottom of screen after rating is selected
    setTimeout(() => {
      window.scrollTo({
        top: document.documentElement.scrollHeight,
        behavior: 'smooth'
      });
    }, 100);
  };

  const handleMessageBoxChange = (event) => {
    setMessage(event.target.value);
  };

  function submit() {
    submitRating(rating, message)
    addToast(toastOk);
  }

  const track = (name) => {
    return () => {
      trackButtonClick(name);
    };
  };

  const titleStyle = {
    textAlign: 'center',
    color: theme.palette.textPrimary,
    fontFamily: 'DM Serif Display, serif',
    fontWeight: 400,
    fontSize: '30px',
  };

  return (
    <CContainer
      fluid
      className="g-1 px-1 pt-1 d-flex flex-column"
      style={{
        color: theme.palette.textPrimary,
        height: "100%",
        overflowY: "scroll",
        overflowX: "hidden",
        boxShadow: "0px -4px 8px rgba(0, 0, 0, 0.8)",
        paddingBottom: '10vh'
      }}
    >
      <picture className="pt-5 w-100 d-flex justify-content-center align-items-center">
        {info.branding.logo.webp && <source srcSet={info.branding.logo.webp} type="image/webp" />}
        {info.branding.logo.avif && <source srcSet={info.branding.logo.avif} type="image/avif" />}
        {info.branding.logo.jpg && <source srcSet={info.branding.logo.jpg} type="image/avif" />}
        <CImage
          srcSet={`${info.branding.logo.jpg},${info.branding.logo.webp}`}
          style={{
            width: '40vw',
            aspectRatio: '1/1',
            objectFit: 'cover',
          }}
        />
      </picture>
      {links && Object.keys(links).length !== 0 && (
        <>
          <div
            style={{ paddingLeft: '20px', paddingRight: '20px', display: "flex", justifyContent: "center", width: "100%" }}
            // use className instead of style
            className="px-5 py-2 d-flex justify-content-center align-items-center"
          >
            <h4 style={titleStyle}>Retrouvez-nous sur les réseaux sociaux!</h4>
          </div>
          <div className="d-flex flex-column justify-content-evenly px-5 py-1 align-items-center w-100">
            {links["facebook"] && (
              <Link className="w-100 px-1 py-2" to={links["facebook"]} target="_blank" rel="noopener noreferrer">
                <CButton
                  color="light"
                  size="lg"
                  className="w-100 bg-info text-white text-uppercase font-weight-bold"
                  style={{
                    background: "",
                    fontWeight: "bold",
                    fontSize: "small",
                    minWidth: 115,
                  }}
                  onClick={track("Facebook")}
                >
                  <CIcon icon={cibFacebook} /> Facebook
                </CButton>
              </Link>
            )}
            {links["instagram"] && (
              <Link className="w-100 px-1 py-2" to={links["instagram"]} target="_blank" rel="noopener noreferrer">
                <CButton
                  color="light"
                  size="lg"
                  className="w-100 bg-light text-white text-uppercase font-weight-bold"
                  style={{
                    background:
                      "radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%,#d6249f 60%,#285AEB 90%)",
                    fontWeight: "bold",
                    fontSize: "small",
                    minWidth: 115,
                  }}
                  onClick={track("Instagram")}
                >
                  <CIcon icon={cibInstagram} /> Instagram
                </CButton>
              </Link>
            )}
            {links["tiktok"] && (
              <Link className="w-100 px-1 py-2" to={links["tiktok"]} target="_blank" rel="noopener noreferrer">
                <CButton
                  color="light"
                  size="lg"
                  className="text-black w-100 text-uppercase font-weight-bold"
                  style={{
                    background: "#00eae7",
                    fontWeight: "bold",
                    fontSize: "small",
                    minWidth: 115,
                  }}
                  onClick={track("Tiktok")}
                >
                  <CIcon icon={cibTiktok} />{" "}
                  &nbsp;&nbsp;&nbsp;Tiktok&nbsp;&nbsp;&nbsp;
                </CButton>
              </Link>
            )}
          </div>
        </>
      )}
      <CRow className="d-flex flex-column justify-content-center align-items-center mt-3">
        <div
          style={{ display: "flex", justifyContent: "center", width: "100%" }}
        >
          <h4 style={titleStyle}>Votre avis nous intéresse!</h4>
        </div>
        <StarRating rating={rating} onRatingChange={handleRatingChange} />
        {rating === 0 ? null : (
          <>
            {rating <= 3 ? (
              <CForm
                className="pb-3 mt-3 w-100 px-5"
                style={{ backgroundColor: theme.palette.background, color: theme.palette.textPrimary }}
              >
                <CFormLabel htmlFor="exampleFormControlTextarea1">
                  Nous sommes désolés que votre expérience n'ait pas été à la
                  hauteur de vos attentes. Nous serions ravis d'avoir vos
                  impressions et suggestions.
                </CFormLabel>
                <CFormTextarea
                  id="exampleFormControlTextarea1"
                  label="Votre commentaire:"
                  rows={4}
                  value={message}
                  onChange={handleMessageBoxChange}
                ></CFormTextarea>
              </CForm>
            ) : (
              <div className="pb-3 mt-3 w-100 px-5 d-flex justify-content-center align-items-center" style={{ color: theme.palette.textPrimary }}>
                <p className="text-center">
                  Merci! {links["maps"] && (
                    <span>
                      Si vous avez le temps, laissez-nous également un avis sur{" "}
                      <a href={links["maps"]} style={{ color: theme?.palette?.primary.main || theme?.palette?.primary }} target="_blank" rel="noopener noreferrer" onClick={track("gmaps")}>
                        Google Maps
                      </a>
                    </span>
                  )}
                </p>
              </div>
            )}
          </>
        )}
        {rating !== 0 &&
          <div className="d-flex justify-content-center align-items-center">
            <CButton
              color="light"
              size="lg"
              className="text-uppercase font-weight-bold py-3 mt-3"
              style={{
                fontWeight: "bold",
                fontSize: "small",
                background: theme?.palette?.primary.main || theme?.palette?.primary,
                color: theme.palette.onPrimary
              }}
              onClick={submit}
            >
              Envoyer
            </CButton>
          </div>
        }
      </CRow>
      <CToaster ref={toaster} push={toast} placement="bottom" />
    </CContainer>
  );
};

export default React.memo(Rate);
