import { CContainer, CAlert } from "@coreui/react";
import React, { useEffect, useState, useCallback, Suspense, useRef } from "react";
import { Outlet, useLocation, useNavigate, useParams } from "react-router-dom";
import "./App.css";
import BottomNavbar from "./components/BottomNavbar";
import "./scss/style.scss";
import useApiDataStore from "./useApiDataStore";
import posthog from 'posthog-js';
import CoreUIThemeProvider from "./theme/CoreUiThemeProvider";
import useTheme from "./hooks/useTheme";
import Loader from "./components/Loader";

function App() {
  const location = useLocation();
  const { eateryId, tableIdOrOrderId: urlParam } = useParams();
  const { setLoading, loading, info: eatery, getEateryInfo, isWsConnected, findOrder, connectToWebSocket, order, tableIdOrOrderId, setTableIdOrOrderId } = useApiDataStore();
  const theme = useTheme();
  const [activeKeyIndex, setActiveKeyIndex] = useState(null);
  const [isStartRoute, setIsStartRoute] = useState(true);
  const [isFirstTime, setIsFirstTime] = useState(true);
  const navigate = useNavigate();
  const hasAttemptedConnection = useRef(false);

  useEffect(() => {
    if (eatery) {
      const pageTitle = document.title;
      const pagePath = location.pathname;

      posthog.capture('$pageview', {
        title: pageTitle,
        path: pagePath,
      });
    }
  }, [location, eatery]);

  useEffect(() => {
    const fetchOrder = async () => {
      if (!order.id && (location.pathname.includes("/start")
        || location.pathname.includes("/menu")
        || location.pathname.includes("/order")
        || location.pathname.includes("/rate"))) {
        setLoading(true);
        const foundOrder = await findOrder(eateryId, tableIdOrOrderId);
        if (foundOrder) {
          if (!isWsConnected && !hasAttemptedConnection.current) {
            hasAttemptedConnection.current = true;
            await connectToWebSocket(eateryId, foundOrder.table?.id || foundOrder.order.id);
          }
          setTimeout(() => {
            setLoading(false);
          }, 1000);
        } else {
          setLoading(false);
          navigate("/" + eateryId);
        }
      }
      setLoading(false);
    };

    fetchOrder();
  }, [order.id, location.pathname]);

  useEffect(() => {
    const initializeConnection = async () => {
      if (hasAttemptedConnection.current || isWsConnected) return;

      if (urlParam && /^[0-9a-fA-F]{24}$/.test(urlParam)) {
        hasAttemptedConnection.current = true;
        setTableIdOrOrderId(urlParam);
        await connectToWebSocket(eateryId, urlParam);
      }

      getEateryInfo(eateryId, urlParam || null);
    };

    initializeConnection();
  }, [eateryId, urlParam]);

  const showNotification = useCallback(() => {
    new Notification(eatery.name, {
      body: "Votre commande a été validée.",
      vibrate: [200, 100, 200]
    });
  }, [eatery?.name]);

  const handleNotification = useCallback(() => {
    if (!("Notification" in window)) {
    } else if (Notification.permission === "granted") {
      showNotification();
    } else if (Notification.permission !== "denied") {
      Notification.requestPermission().then(function (permission) {
        if (permission === "granted") {
          showNotification();
        }
      });
    }
  }, [showNotification]);

  useEffect(() => {
    if (order?.status === 'VALIDATED') {
      navigate(`/${eateryId}/${tableIdOrOrderId}/order`);
      handleNotification();
      setActiveKeyIndex(2);
    }
  }, [order, navigate, eateryId, tableIdOrOrderId, handleNotification])

  useEffect(() => {
    // Pattern to match /{eateryId}/{tableId}/{whatever}, with whatever being optional
    const pattern = /^\/[a-fA-F0-9]{24}(\/[a-fA-F0-9]{24})?(\/start|\/)?\/?$/;
    setIsStartRoute(pattern.test(location.pathname));
  }, [location]);

  useEffect(() => {
    if (isWsConnected && isFirstTime) {
      setIsFirstTime(false);
    }
  }, [isWsConnected, isFirstTime]);

  useEffect(() => {
    return () => {
      hasAttemptedConnection.current = false;
      useApiDataStore.getState().handleDisconnect();
    };
  }, []);

  if (loading || !eatery || !theme) {
    return <div className="d-flex justify-content-center" style={{ height: "100vh" }}>
      <Loader color={theme?.palette?.primary.main || theme?.palette?.primary} />
    </div>;
  }

  document.title = eatery.name;

  return (
    <CoreUIThemeProvider>
      <CContainer
        id="main-container"
        className="g-0 d-flex flex-column align-items-center"
        style={{
          backgroundColor: theme?.backgroundColor ? theme.backgroundColor : 'transparent',
          minHeight: '100vh',
          margin: '0 auto',
          maxWidth: '600px',
          width: '100%',
          height: '100vh',
          padding: '0',
        }}
      >
        {!isWsConnected && !isFirstTime && (
          <CAlert color="danger" className="m-2">
            Vous avez été déconnecté de la commande. Tentative de reconnexion...
          </CAlert>
        )}
        <CContainer
          className="g-0 flex-grow-1"
          style={{
            width: '100%',
            height: '100%',
            padding: '0 0rem'
          }}
        >
          <Outlet />
        </CContainer>
        {!isStartRoute && <BottomNavbar activeKeyIndex={activeKeyIndex} />}
      </CContainer>
    </CoreUIThemeProvider>
  );
}

export default App;