import { create } from "zustand";
import { config } from './config.js';
import SockJS from 'sockjs-client';
import { Client } from '@stomp/stompjs';
import { getCookie, getOrSetUserId, setCookie } from "./utils/cookies.js";

var eateryid;
var stompClient;

const initialOrderState = {
  id: null,
  eateryId: null,
  tableId: null,
  orderType: null,
  creator: null,
  people: [],
  foodItems: [],
  additionalNote: null,
  status: "CREATED",
};

const useApiDataStore = create((set, get) => ({
  loading: true,
  info: null,
  menu: null,
  currency: null,
  branding: null,
  links: {},
  categories: null,
  supercategories: null,
  table: {
    id: null,
    name: null,
    eateryId: null,
  },
  order: initialOrderState,
  tableIdOrOrderId: null,
  userId: null,
  isWsConnected: false,

  setLoading: (loading) => set({ loading }),

  setOrderType: (orderType) => {
    const order = get().order;
    set({ order: { ...order, orderType } });
  },

  findOrder: async (eateryId, tableIdOrOrderId) => {
    const uId = getOrSetUserId();
    set({ userId: uId });
    let cachedOrder;

    if (!tableIdOrOrderId) {
      tableIdOrOrderId = getCookie('tableIdOrOrderId') || '';
      if (tableIdOrOrderId === '') return null;
    } else {
      // Fetch cached order based on `tableIdOrOrderId`
      const orderResponse = await fetch(`${config.API_URL}/api/orders/${eateryId}/${tableIdOrOrderId}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'userId': uId,
        }
      });
      if (orderResponse.status === 404) return null;
      cachedOrder = await orderResponse.json();
    }

    if (cachedOrder?.order?.id) {
      tableIdOrOrderId = cachedOrder.table?.id || cachedOrder.order.id;
    }

    setCookie('tableIdOrOrderId', tableIdOrOrderId, 3);

    return cachedOrder;
  },

  createOrder: async (eateryId, tableIdOrOrderId, orderType) => {
    const uId = getOrSetUserId();
    set({ userId: uId });
    let cachedOrder;

    const newOrderResponse = await fetch(`${config.API_URL}/api/orders/${eateryId}/order`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'userId': uId,
      },
      body: JSON.stringify({ ...get().order, eateryId, orderType }),
    });
    cachedOrder = await newOrderResponse.json();

    // Set the new tableIdOrOrderId from the created order
    tableIdOrOrderId = cachedOrder.order.id;
    set({ tableIdOrOrderId: tableIdOrOrderId });
    setCookie('tableIdOrOrderId', tableIdOrOrderId, 3);
    set({ order: cachedOrder.order, table: cachedOrder.table });

  },

  connectToWebSocket: async (eateryId, tableIdOrOrderId) => {
    const { isWsConnected } = get();
    console.log("isWsConnected", isWsConnected);
    if (isWsConnected) return;

    const uId = getOrSetUserId();
    set({ userId: uId });
    console.log("userId", uId);

    let cachedOrder;

    if (!tableIdOrOrderId) {
      console.log("tableIdOrOrderId is not available.");
      tableIdOrOrderId = getCookie('tableIdOrOrderId') || '';
      // Create a new cached order if no tableIdOrOrderId is available
      console.log("Creating new order with params eateryId:", eateryId, "tableIdOrOrderId:", tableIdOrOrderId);
      const newOrderResponse = await fetch(`${config.API_URL}/api/orders/${eateryId}/${tableIdOrOrderId}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'userId': uId,
        }
      });
      if (newOrderResponse.status === 404) {
        console.log("Creating new order...");
        const newOrderResponse = await fetch(`${config.API_URL}/api/orders/${eateryId}/order`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'userId': uId,
          },
          body: JSON.stringify(get().order),
        });
        cachedOrder = await newOrderResponse.json();
      } else {
        cachedOrder = await newOrderResponse.json();
      }
      // Set the new tableIdOrOrderId from the created order
      tableIdOrOrderId = cachedOrder.order.id;
    } else {
      // Fetch cached order based on `tableIdOrOrderId`
      const orderResponse = await fetch(`${config.API_URL}/api/orders/${eateryId}/${tableIdOrOrderId}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'userId': uId,
        }
      });
      if (orderResponse.status === 404) {
        console.log("Could not find order with tableIdOrOrderId:", tableIdOrOrderId);
        return;
      } else {
        cachedOrder = await orderResponse.json();
      }
    }


    if (cachedOrder?.order?.id) {
      set({ order: cachedOrder.order, table: cachedOrder.table });
      tableIdOrOrderId = cachedOrder.table?.id || cachedOrder.order.id;
      set({ tableIdOrOrderId: tableIdOrOrderId });
    }

    setCookie('tableIdOrOrderId', tableIdOrOrderId, 3);

    // WebSocket connection
    const socket = new SockJS(`${config.API_URL}/ws`);
    stompClient = new Client({
      webSocketFactory: () => socket,
      connectHeaders: {
        userId: uId,
        eateryId: eateryId,
        tableIdOrOrderId: cachedOrder.tableId || cachedOrder.order.id,
      },
      onConnect: () => {
        set({ order: cachedOrder.order, table: cachedOrder.table });
        set({ isWsConnected: true });

        const orderUpdatesUrl = `/topic/orders/${eateryId}/${cachedOrder.tableId || cachedOrder.order.id}`;
        stompClient.subscribe(orderUpdatesUrl, (message) => {
          const { order: updatedOrder, table: updatedTable } = JSON.parse(message.body);
          set({ order: updatedOrder, table: updatedTable });
          set({ isWsConnected: true });
        });

        // Subscribe to order deletions
        const orderDeletionsUrl = `/topic/orders/${eateryId}/${cachedOrder.tableId || cachedOrder.order.id}/deletions`;
        stompClient.subscribe(orderDeletionsUrl, () => {
          // Reset order to initial state
          set({
            order: initialOrderState,
            table: {
              id: null,
              name: null,
              eateryId: null,
            }
          });
          alert("Commande supprimée. Redirection vers l'écran d'accueil.");
          stompClient.deactivate();
          set({ isWsConnected: false });
        });
      }
    });

    stompClient.activate();
  },

  handleDisconnect: () => {
    if (stompClient?.connected) {
      stompClient.publish({
        destination: "/app/order/disconnect",
        body: JSON.stringify({
          eateryId: eateryid,
          tableIdOrOrderId: get().table?.id || get().order?.id,
          userId: get().userId,
        })
      });
      stompClient.deactivate();
      set({ isWsConnected: false });
    }
  },

  setTableIdOrOrderId: (tableIdOrOrderId) => {
    set({ tableIdOrOrderId })
  },

  getEateryInfo: async (eateryId, tableIdOrOrderId = null) => {
    set({ loading: true });

    try {
      // Fetch eatery info first
      const response = await fetch(`${config.API_URL}/api/eatery/menu/${eateryId}/${tableIdOrOrderId || ''}`);
      const data = await response.json();

      // Set html page elements
      document.body.style.backgroundColor = data.eatery.branding.theme.palette.background;
      document.title = data.eatery.name;

      // Set global state for eatery info
      const orderCopy = get().order;
      eateryid = data.eatery.id;
      set({
        info: data.eatery,
        menu: data.menu,
        currency: data.eatery.currency,
        categories: data.categories,
        supercategories: data.supercategories,
        branding: data.eatery.branding,
        links: data.eatery.links,
        table: data.table,
        order: { ...orderCopy, eateryId: data.eatery.id },
      });
    } catch (error) {
      console.error("An error occurred while connecting to the server.", error);
      throw error;
    } finally {
      set({ loading: false });
    }
  },

  getOrder: () => {
    const order = get().order;
    sessionStorage.setItem(order?.id);
    return get().order;
  },

  setOrder: async () => {
    const { order } = get();

    const cachedOrder = {
      id: order.id,
      eateryId: eateryid,
      tableId: order.tableId,
      orderType: order.orderType,
      creator: order.creator,
      people: order.people,
      foodItems: order.foodItems,
      additionalNote: order.additionalNote,
      status: order.status || "CREATED",
    };

    const requestOptions = {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'userId': get().userId,
      },
      body: JSON.stringify(cachedOrder),
    };

    const response = await fetch(`${config.API_URL}/api/orders/${cachedOrder.eateryId}/${cachedOrder.tableId || cachedOrder.id}`, requestOptions);
    const data = await response.json();

    set({ order: data.order, table: data.table });
  },

  submitRating: async (rating, message) => {
    function getBrowserAndPhoneInfo() {
      const userAgent = navigator.userAgent;

      // Identify the browser
      let browser = "Unknown Browser";
      if (userAgent.includes("Chrome")) browser = "Chrome";
      if (userAgent.includes("Firefox")) browser = "Firefox";
      if (userAgent.includes("Safari") && !userAgent.includes("Chrome")) browser = "Safari";
      if (userAgent.includes("Edge")) browser = "Edge";
      if (userAgent.includes("Opera") || userAgent.includes("OPR")) browser = "Opera";

      // Identify the phone brand
      let phoneBrand = "Unknown Device";
      if (userAgent.includes("iPhone")) phoneBrand = "Apple iPhone";
      if (userAgent.includes("Android")) {
        if (userAgent.includes("Samsung")) phoneBrand = "Samsung";
        else if (userAgent.includes("Huawei")) phoneBrand = "Huawei";
        else if (userAgent.includes("Xiaomi")) phoneBrand = "Xiaomi";
        else phoneBrand = "Generic Android";
      }

      return { browser, phoneBrand };
    }

    const { order } = get();
    const { browser, phoneBrand } = getBrowserAndPhoneInfo();
    const postData = {
      eateryId: eateryid,
      orderId: order.id,
      browser: browser,
      phoneBrand: phoneBrand,
      rating: rating,
      message: message,
    };

    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(postData),
    };

    const response = await fetch(`${config.API_URL}/api/ratings`, requestOptions);

    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }
  },

  getItemById: (id) => {
    if (!get().menu) {
      return null;
    }
    const menu = get().menu;
    for (const category in menu) {
      const item = menu[category].find(item => item.id === id);
      if (item) return item;
    }
    return null;
  },

  confirmOrder: async (phoneNumber) => {
    const { order } = get();

    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: phoneNumber
    };

    const response = await fetch(
      `${config.API_URL}/api/orders/${order.eateryId}/${order.tableId || order.id}/confirm`,
      requestOptions
    );

    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    const cachedOrder = await response.json();

    set({ order: cachedOrder.order, table: cachedOrder.table });
  },

  openOrder: async () => {
    const { order } = get();

    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
    };

    const response = await fetch(`${config.API_URL}/api/orders/${order.eateryId}/${order.tableId || order.id}/open`, requestOptions);

    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    const cachedOrder = await response.json();

    set({ order: cachedOrder.order, table: cachedOrder.table });
  }
}));

export default useApiDataStore;
